.convert-kit-stuff input {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: color-bg(input);
  border-width: $button-form--border-width;
  border-style: $button-form--border-style;
  border-color: color-border(input);
  padding-left: ($form--padding-h - $button-form--border-width) * 2 + 16;
  background-color: color-bg(input-inverse);
  border-color: color-border(input-inverse);
  display: block;
  @include font-size(input);
  @include font-weight(input);
  padding: (
      (
          $button-form--height - get-line-height(input) -
            ($button-form--border-width * 2)
        ) / 2
    )
    ($form--padding-h - $button-form--border-width);
  height: $button-form--height;
  border-radius: $form--radius;
  color: color(input);
  box-shadow: none;
  max-width: 100%;
  width: 100%;

  &:hover {
    border-color: color-border(input-hover-inverse);
  }

  &:active,
  &:focus {
    border-color: color-border(input-focus-inverse);
    background-color: color-bg(input-focus-inverse);
  }

  &[disabled] {
    background-color: color-bg(input-disabled-inverse);
    border-color: color-border(input-disabled-inverse);
  }

  &::placeholder {
    color: color(input-placeholder);
  }

  &::-ms-input-placeholder {
    color: color(input-placeholder);
  }

  &:hover {
    border-color: color-border(input-hover);
  }

  &:active,
  &:focus {
    outline: none;
    border-color: color-border(input-focus);
    background-color: color-bg(input-focus);
  }
}

.convert-kit-stuff form {
  width: 100%;
  display: flex;
}
