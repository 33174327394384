// Colors

// The color palette
$palette: (
  light: (
    1: #ffffff,
    2: #f0f4fd,
    3: #d9e0f0,
  ),
  dark: (
    1: #2b2b52,
    2: #58678c,
    3: #95a1bc,
  ),
  primary: (
    // 1: #8e79fc,
    1: #fdf251,
    2: #b8abfd,
    3: #6448fb,
    4: #e2ddfe,
  ),
  secondary: (
    // 1: #6eb0fc,
    1: #3caea3,
    2: #a0cbfd,
    3: #3c95fb,
    4: #d2e6fe,
  ),
  alert: (
    error: #ff7272,
    warning: #ffbf77,
    success: #4ed3a1,
  ),
);

// Base typography: color
$color: (
  heading: get-color(dark, 1),
  base: get-color(dark, 2),
  high-contrast: get-color(dark, 1),
  mid-contrast: get-color(dark, 2),
  low-contrast: get-color(dark, 3),
  primary: get-color(primary, 1),
  secondary: get-color(secondary, 1),
  error: get-color(alert, error),
  warning: get-color(alert, warning),
  success: get-color(alert, success),
  // ↓ Inverted colors
  heading-inverse: get-color(light, 1),
  base-inverse: get-color(dark, 3),
  high-contrast-inverse: get-color(light, 1),
  mid-contrast-inverse: get-color(light, 2),
  low-contrast-inverse: get-color(light, 3),
);

// Borders and dividers: color
$border--color: (
  divider: get-color(light, 3),
  divider-inverse: get-color(dark, 2),
);

// Icons: fill color
$icon--color: (
  hamburger: get-color(dark, 1),
  // hamburger icon
  accordion: get-color(primary, 1),
  // accordion icon
  social: get-color(dark, 1),
  // social icons
  social-hover: get-color(dark, 2),
  // social icons (:hover)
  modal: get-color(light, 3),
  // modal icon
  modal-hover: get-color(dark, 3),
  // modal icon (:hover)
  news-more: get-color(primary, 2),
  // news - read more link arrow
  // ↓ Inverted colors
  hamburger-inverse: get-color(light, 1),
  // hamburger icon
  accordion-inverse: get-color(light, 1),
  // accordion icon
  social-inverse: get-color(light, 1),
  // social icons
  social-hover-inverse: get-color(light, 1) // social icons (:hover),,,,,,,,,,,
);

// Misc elements: background color
$bg--color: (
  body: get-color(light, 1),
  body-shadow: 0 20px 48px rgba(get-color(dark, 1), 0.1),
  // body shadow when a boxed layout is used (set to null if don't want a shadow)
  body-outer: get-color(light, 2),
  // outer bg color when a boxed layout is used
  bg-color: get-color(dark, 1),
  // .has-bg-color helper class
  shadow: 0 32px 64px rgba(get-color(dark, 1), 0.24),
  // .has-shadow helper class
  code: get-color(light, 2),
  code-inverse: darken(get-color(dark, 1), 3%),
);
