.button {
  @include font-family(heading);
}

@include media("<=medium") {
  .header-nav {
    .button {
      color: color(button-light);
      background-color: color-bg(button-light);
      border-color: color-border(button-light);

      &:hover {
        background-color: color-bg(button-light-hover);
        border-color: color-border(button-light-hover);
      }
    }
  }
}

.convert-kit-stuff button {
  @include font-family(heading);
  display: inline-flex;
  @include font-size(button);
  @include font-weight(button);
  padding: (
      (
          $button-form--height - get-line-height(button) -
            ($button-form--border-width * 2)
        ) / 2
    )
    ($button--padding-h - $button-form--border-width);
  height: $button-form--height;
  text-decoration: none !important;
  text-transform: $button--transform;
  color: color(button-light);
  background-color: color-bg(button-light);
  border-width: $button-form--border-width;
  border-style: $button-form--border-style;
  border-color: color-border(button-light);
  border-radius: $button--radius;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  letter-spacing: inherit;
  white-space: nowrap;
  transition: background 0.15s ease;

  &:active {
    outline: 0;
  }

  &:hover {
    background-color: color-bg(button-light-hover);
    border-color: color-border(button-light-hover);
  }

  color: color(button-primary);
  background-color: color-bg(button-primary);
  border-color: color-border(button-primary);

  &:hover {
    background-color: color-bg(button-primary-hover);
    border-color: color-border(button-primary-hover);
  }

  &.is-loading {
    &::after {
      border-color: color(button-primary);
    }
  }
}
